const ItalyFlag = ({ height, width }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#F0F0F0" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="b">
          <stop stopColor="#E43D4C" offset="0%" />
          <stop stopColor="#CC2E3C" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="c">
          <stop stopColor="#1BB65D" offset="0%" />
          <stop stopColor="#139149" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="url(#a)" d="M0 0h15v15H0z" />
        <path fill="url(#b)" d="M7 0h8v15H7z" />
        <path fill="url(#c)" d="M0 0h5v15H0z" />
        <path fill="url(#a)" d="M5 0h5v15H5z" />
      </g>
    </svg>
  );
};

const FranceFlag = ({ height, width }) => {
  return (
<svg width={width} height={height}  xmlns="http://www.w3.org/2000/svg" id="flag-icons-fr" viewBox="0 0 512 512">
  <g fillRule="evenodd" strokeWidth="1pt">
    <path fill="#fff" d="M0 0h512v512H0z"/>
    <path fill="#002654" d="M0 0h170.7v512H0z"/>
    <path fill="#ce1126" d="M341.3 0H512v512H341.3z"/>
  </g>
</svg>

  );
};

const UkFlag = ({ height, width }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb-eng" viewBox="0 0 512 512">
      <path fill="#fff" d="M0 0h512v512H0z" />
      <path fill="#ce1124" d="M215 0h82v512h-82z" />
      <path fill="#ce1124" d="M0 215h512v82H0z" />
    </svg>
  );
};

export { ItalyFlag, FranceFlag, UkFlag };
