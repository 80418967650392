import { useState, useRef } from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
// BOOTSTRAP
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
// COMPONENTS
import Logo from "@/components/ui/Logo/Logo";
const CustomModal = dynamic(() => import("@/components/portals/CustomModal/CustomModal"), { ssr: false });
import SignupForm from "@/components/core/SignupForm/SignupFormComponent";
// CONTEXT
import { useAuth } from "components/auth/hooks/useAuth";
import { useModal } from "@/components/context/ModalContext";
// ICONS
import { WhatsAppIcon, InstagramIcon } from "@/public/icons/icons";
import { ItalyFlag, FranceFlag, UkFlag } from "@/public/icons/flags";
// STYLE
import s from "./Footer.module.scss";
import { useApp } from "@/components/context/AppContext/AppContext";
import fallbackTranslation from "@/lib/dbTranslations";
import { deleteCookie, getCookie, hasCookie, setCookie } from "cookies-next";

const DOMAIN = !process.env.NEXT_PUBLIC_ENV ? "localhost" : ".kesy.it"; // Cookie domain
const USER_CONSENT_COOKIE_KEY = "OnFashion.Cookie"; // Cookie name
const USER_CONSENT_COOKIE_EXPIRE_DATE = 365 * 24 * 60 * 60; // One year expiration date in seconds

const shoppingKesy = [
  { title: "contact", link: "/contact" },
  { title: "shipment", link: "/delivery" },
  { title: "payments", link: "/payments" },
  { title: "returns", link: "/returns" },
];

const informations = [
  { title: "privacy", link: "/privacy-and-cookie" },
  { title: "terms-condition", link: "/terms-and-conditions" },
];

export default function Footer() {
  const { t } = useTranslation(["common", "footer", "db-translations"]);
  const app = useApp();
  const auth = useAuth();
  const router = useRouter();

  const { pathname, asPath, query } = router;
  const { showModal } = useModal();
  const performanceCookies = useRef(hasCookie(USER_CONSENT_COOKIE_KEY) && getCookie(USER_CONSENT_COOKIE_KEY) ? true : false);
  const [modalShow, setModalShow] = useState(false);

  // Signup Modal
  const showSignupModal = () =>
    showModal(CustomModal, {
      backdrop: "static",
      children: <SignupForm />,
      closeButton: true,
    });

  function CookiesSettingsModal(props) {
    function onSubmitPreferences() {
      // Set consent cookie to
      if (performanceCookies.current !=getCookie(USER_CONSENT_COOKIE_KEY)) {
        try {
          // Set user preference cookie
          setCookie(USER_CONSENT_COOKIE_KEY, performanceCookies.current, {
            path: "/",
            sameSite: true,
            maxAge: USER_CONSENT_COOKIE_EXPIRE_DATE,
          });
          // Remove all previous set cookies (Google Analytics)
          if (performanceCookies.current == false) {
            deleteCookie("_ga", { path: "/", domain: DOMAIN }); // Google Analytics
            deleteCookie("_ga_3FFMSFNWE2", { path: "/", domain: DOMAIN }); // Google Analytics
            deleteCookie("_ga_4BQWNECETS", { path: "/", domain: DOMAIN }); // Google Analytics
          }
        } catch (error) {}
      }
    }
    return (
      <Modal {...props} centered dialogClassName="cookies-settings-modal" aria-labelledby="cookies-settings-modal" className="px-0" size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-center w-100">
            <h4>{t("modals.consent-preferences", { ns: "common" })}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pb-3">
            <div className="d-flex">
              <h5> {t("modals.cookies-settings-t1", { ns: "common" })}</h5>
              <span className="ps-2 text-success fw-medium">{t("modals.always-on", { ns: "common" })}</span>
            </div>
            <p className="fs-6">{t("modals.cookies-settings-p1", { ns: "common" })}</p>
          </div>
          <div className="pb-3">
            <Form>
              <Form.Check
                onClick={() => (performanceCookies.current = !performanceCookies.current)}
                defaultChecked={performanceCookies.current}
                type="switch"
                id="analytics-switch"
                label={<h5>{t("modals.cookies-settings-t2", { ns: "common" })}</h5>}
              />
            </Form>
            <p className="fs-6">{t("modals.cookies-settings-p2", { ns: "common" })}</p>
          </div>
          <Row className="mx-0" style={{ display: "flex", justifyContent: "end", maxWidth: "80rem" }}>
            <Col xs={12} md={4} className="p-2">
              <Button
                variant="dark w-100 text-nowrap py-2"
                onClick={() => {
                  onSubmitPreferences();
                  props.onHide();
                }}
              >
                {t("modals.save", { ns: "common" }).toUpperCase()}
              </Button>
            </Col>
            <Col xs={12} md={4} className="p-2">
              <Button variant="dark w-100 text-nowrap py-2" onClick={props.onHide}>
                {t("modals.close", { ns: "common" }).toUpperCase()}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <Container fluid className={`pb-5 ${s.footer}`}>
        <Container fluid className={`flex-row px-0 contentMaxWidth1140`}>
          {/* MOBILE FOOTER */}
          <Container className="pt-5 px-4">
            <Col>
              <Accordion className={`${s.accordion_style} d-md-none`} flush>
                <Accordion.Item eventKey="0" className={s.accordion_style}>
                  <Accordion.Header className={s.accordion_header}>
                    <span className="fs-7 fw-medium">{t("category", { ns: "footer" }).toUpperCase()}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="nobulletsul px-0">
                      <li className="mb-3 fs-7">
                        <Link href="/catalogue/ALL_PRODUCTS?Page=1">{t("see-all", { ns: "footer" })}</Link>
                      </li>
                      {app.appConfig?.clothingProductsGroup?.length > 0 &&
                        app.appConfig?.clothingProductsGroup.slice(0, 3).map((item, index) => (
                          <li key={index} className="mb-3 fs-7">
                            <Link href={`/catalogue/${item.name}?Page=1`} passHref>
                              {fallbackTranslation("clothing-product-group", "db-translations", item, t, router.locale)}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className={`${s.accordion_style} d-md-none`} flush>
                <Accordion.Item eventKey="0" className={s.accordion_style}>
                  <Accordion.Header className={`${s.accordion_header}`}>
                    <span className="fs-7 fw-medium">{t("shopping-kesy", { ns: "footer" })}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="nobulletsul px-0">
                      {shoppingKesy.map((item, index) => (
                        <li key={index} className="mb-3 fs-7">
                          <Link href={item.link}>{t(item.title, { ns: "footer" })}</Link>
                        </li>
                      ))}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className={`${s.accordion_style} d-md-none`} flush>
                <Accordion.Item eventKey="0" className={s.accordion_style}>
                  <Accordion.Header className={`${s.accordion_header}`}>
                    <span className="fs-7 fw-medium">{t("informations", { ns: "footer" })}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="nobulletsul px-0">
                      {informations.map((item, index) => (
                        <li key={index} className="mb-3 fs-7">
                          <Link href={item.link}>{t(item.title, { ns: "footer" })}</Link>
                        </li>
                      ))}
                      <li className="mb-3 fs-7">
                        <a style={{ cursor: "pointer" }} onClick={() => setModalShow(true)}>
                          {t("Cookies settings", { ns: "footer" })}
                        </a>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Container>
          {/* Row for links */}
          <Row className={`px-3 mx-0 ${s.containerColLinks}`}>
            <Col className={`px-0 d-none d-md-block ${s.colLinks}`}>
              <ul className="nobulletsul px-0">
                <li className="mb-4 fs-7 fw-medium">
                  <span>{t("category", { ns: "footer" }).toUpperCase()}</span>
                </li>
                <li className="mb-3 fs-7">
                  <Link href="/catalogue/ALL_PRODUCTS?Page=1">{t("see-all", { ns: "footer" })}</Link>
                </li>
                {app.topCategories &&
                  app.topCategories.slice(0, 3).map((listItem, index) => (
                    <li key={index} className="mb-3 fs-7">
                      <Link href={`/catalogue/${listItem.key.toLowerCase()}?Page=1`} passHref>
                        {fallbackTranslation("clothing-product-group", "db-translations", listItem, t, router.locale)}
                      </Link>
                    </li>
                  ))}
              </ul>
            </Col>
            <Col className={`ps-0 pe-2 d-none d-md-block ${s.colLinks}`}>
              <ul className="nobulletsul px-0">
                <li className="mb-4 fs-7 fw-medium">
                  <span>{t("shopping-kesy", { ns: "footer" })}</span>
                </li>
                {shoppingKesy.map((item, index) => (
                  <li key={index} className="mb-3 fs-7">
                    <Link href={item.link}>{t(item.title, { ns: "footer" })}</Link>
                  </li>
                ))}
              </ul>
            </Col>
            <Col className={`ps-0 pe-2 d-none d-md-block ${s.colLinks}`}>
              <ul className="nobulletsul px-0">
                <li className="mb-4 fs-7 fw-medium">
                  <span>{t("informations", { ns: "footer" })}</span>
                </li>
                {informations.map((item, index) => (
                  <li key={index} className="mb-3 fs-7">
                    <Link href={item.link}>{t(item.title, { ns: "footer" })}</Link>
                  </li>
                ))}
                <li className="mb-3 fs-7">
                  <a style={{ cursor: "pointer" }} onClick={() => setModalShow(true)}>
                    {t("Cookies settings", { ns: "footer" })}
                  </a>
                </li>
              </ul>
            </Col>
            {!auth.user && (
              <Col className={`px-0 ${s.colLinks}`}>
                <ul className="nobulletsul px-0">
                  <li className="pt-4 pb-2">
                    <Button
                      variant="link"
                      className="fs-7 fw-medium"
                      onClick={() => {
                        showSignupModal();
                      }}
                    >
                      {t("see-all", { ns: "footer" })}
                    </Button>
                  </li>
                  <li className="fs-7">
                    <span>{t("consult-catalogue", { ns: "footer" })}</span>
                  </li>
                </ul>
              </Col>
            )}
          </Row>
          <Row className={`pt-4 mx-5 ${s.socialContainer}`}>
            <Col className={s.socialCol}>
              <Button
                aria-label="instagram"
                className="px-0"
                variant="link"
                href="https://www.instagram.com/kesy_pronto_moda/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon />
              </Button>
            </Col>
            <Col className={s.socialCol}>
              <Button
                aria-label="whatsapp"
                className="px-0"
                variant="link"
                href={`https://wa.me/${process.env.shopInfo["Contatti"].WhatsApp}`}
                target="_blank"
                rel="noreferrer"
              >
                <WhatsAppIcon width={24} height={24} color="black" strokeWidth={0.1} />
              </Button>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row className="mx-0" style={{ justifyContent: "center", textAlign: "center" }}>
            <p className="fs-7 fw-medium">
              {t("copyright", { ns: "footer" })} {process.env.shopInfo["DatiAnagrafici"].Anagrafica.Denominazione}.
            </p>
          </Row>
          <Row className="mx-0" style={{ justifyContent: "center", textAlign: "center" }}>
            <span className="fs-7 fw-medium">
              {process.env.shopInfo["Sede"].Indirizzo} {process.env.shopInfo["Sede"].NumeroCivico} - {process.env.shopInfo["Sede"].CAP}{" "}
              {process.env.shopInfo["Sede"].Comune} - {t("vat", { ns: "footer" })} {process.env.shopInfo["DatiAnagrafici"].IdFiscaleIVA.IdPaese}
              {process.env.shopInfo["DatiAnagrafici"].IdFiscaleIVA.IdCodice}
            </span>
          </Row>
          <Row className="mx-0" style={{ justifyContent: "center", textAlign: "center" }}>
            <span className="fs-7 fw-medium">
              {t("capital", { ns: "footer" })} {Number(process.env.shopInfo["IscrizioneREA"].CapitaleSociale).toFixed(2) + " €"} -{" "}
              {t("rea", { ns: "footer" })} {process.env.shopInfo["IscrizioneREA"].Ufficio}
              {process.env.shopInfo["IscrizioneREA"].NumeroREA}{" "}
            </span>
          </Row>
          <div className="d-flex justify-content-center pt-4 mx-0">
            <Logo width={53} height={45} className="" href="/" clickable="clickable" footer={true} />
          </div>
        </Container>
        <div className="d-flex align-items-center justify-content-center pt-4 mx-0">
          <div
            className={`d-flex align-items-center px-3 fs-7 ${s.changeLanguage}`}
            onClick={(e) => {
              e.preventDefault();
              router.push({ pathname, query }, asPath, { locale: "it" });
            }}
          >
            <span className="pe-1">IT</span> <ItalyFlag width="15" height="15" />
          </div>
          <div
            className={`d-flex align-items-center px-3 fs-7 ${s.changeLanguage}`}
            onClick={(e) => {
              e.preventDefault();
              router.push({ pathname, query }, asPath, { locale: "fr" });
            }}
          >
            <span className="pe-1">FR</span>
            <FranceFlag width="15" height="15" />
          </div>
          <div
            className={`d-flex align-items-center px-3 fs-7 ${s.changeLanguage}`}
            onClick={(e) => {
              e.preventDefault();
              router.push({ pathname, query }, asPath, { locale: "en" });
            }}
          >
            <span className="pe-1">EN</span> <UkFlag width="15" height="15" />
          </div>
        </div>
      </Container>
      <CookiesSettingsModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
